import { useEffect, useState } from 'react'
import { Card, Col, ConfigProvider, List, Row, theme } from 'antd';
import { ICustomFieldsGetRequest } from '@models/custom-field.model';
import { CustomFieldGroupTypeEnum } from '@models/enumerations/custom-field-group-type-enum.model';
import { ICustomFieldGroupExtendedUI } from '@models/custom-field-group.model';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getCustomFields} from '@store/slices/generic-custom-fields';
import { CustomFieldGenericValue } from './CustomFieldValueGeneric';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { If } from '@components/Utils/Structural';
import { isArrayWithValues } from '@shared/util/array-util';
import { CustomFieldGenericGroupUpdate } from './CustomFieldGenericUpdate';
import { CustomButtonText } from '@components/Utils/CustomButtonText';
import { useTranslation } from 'react-i18next';
import useVisible from '@HOOKs/UseVisible';

interface ICustomFieldsGenericListProps {
    referenceId?: StringORNumber,
    referenceType: keyof typeof CustomFieldGroupTypeEnum
}

export const CustomFieldsGenericList: React.FC<ICustomFieldsGenericListProps> = (props) => {

    const { referenceId, referenceType } = props;

    const dispatch = useAppDispatch();

    const [visibleEditCustomFieldsDrawer, toggleVisibleEditCustomFieldsDrawer] = useVisible();
    
    const listCustomFields = useAppSelector((state) => state.GenericCustomFields[referenceType] );

    const [customFieldSelectedToEdit, setCustomFieldSelectedToEdit] = useState<ICustomFieldGroupExtendedUI>()

    useEffect(() => {
        getInitalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInitalData = async () => {
        if(!referenceId) return;

        const payload: ICustomFieldsGetRequest = {
            referenceId: referenceId,
            referenceType: CustomFieldGroupTypeEnum[referenceType]
        }
        dispatch(getCustomFields(payload))
    }

    const { token: { marginLG, paddingLG, colorTextTertiary, colorTextSecondary, colorFillSecondary, colorBorder, paddingXS } } = theme.useToken();
    const { t } = useTranslation()

    const TitleCard = ({ customFieldGroup }: { customFieldGroup: ICustomFieldGroupExtendedUI }) => {
        const {
          token: { fontSizeHeading5, colorTextSecondary },
        } = theme.useToken();
        return (
          <Row justify={'space-between'} align="middle">
            <Col>
              <span className="font-semibold pl-10" style={{ fontSize: fontSizeHeading5, color: colorTextSecondary }}>
                {customFieldGroup?.name}
              </span>
            </Col>
            <Col>
              <CustomButtonText onClick={() => onEditCustomFieldGroup(customFieldGroup)}>{t('generic.edit')}</CustomButtonText>
            </Col>
          </Row>
        );
    }

    const onEditCustomFieldGroup = (customFieldGroup: ICustomFieldGroupExtendedUI) => {
        setCustomFieldSelectedToEdit(customFieldGroup);
        toggleVisibleEditCustomFieldsDrawer();
    }

    return (
        <>
            <Row style={{ paddingLeft: `${marginLG}px`, paddingRight: `${marginLG}px` }} className="pt-20 pb-40 w-full">
                <List
                    className='w-full'
                    grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                    dataSource={listCustomFields.data}
                    renderItem={(item: ICustomFieldGroupExtendedUI) => (
                        <List.Item>
                            <If condition={isArrayWithValues(item?.customFieldsChildren)} >
                                <ConfigProvider theme={{ components: { Card: { borderRadiusLG: 3 } } }} >
                                    <Card 
                                        title={<TitleCard customFieldGroup={item} />} 
                                        styles={{
                                            header: { paddingLeft: 10, paddingRight: 10, borderColor: colorFillSecondary },
                                            body: { padding: 0, paddingTop: 5 }
                                        }}
                                        style={{ borderColor: colorBorder, borderRadius: 8, overflow: "hidden", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5 }}>
                                        {
                                            item?.customFieldsChildren?.map((item, index) => (
                                                <Row key={item.id} align={"middle"} style={{ paddingLeft: paddingLG, paddingRight: paddingLG }}>
                                                    <Col span={12} className="font-bold" style={{ color: colorTextSecondary , paddingTop: paddingXS, paddingBottom: paddingXS }}>
                                                        { item.label }
                                                    </Col>
                                                    <Col span={12} style={{ color: colorTextTertiary, textAlign: "right" }}>
                                                        <CustomFieldGenericValue customField={item} />
                                                    </Col>
                                                </Row>    
                                            ))
                                        }
                                    </Card>
                                </ConfigProvider>
                            </If>
                        </List.Item>
                    )}
                />
            </Row>
            <Row>
                <If condition={!!(referenceId && customFieldSelectedToEdit && visibleEditCustomFieldsDrawer)} >
                    <CustomFieldGenericGroupUpdate 
                        open={visibleEditCustomFieldsDrawer}
                        toggle={toggleVisibleEditCustomFieldsDrawer}
                        referenceId={referenceId!}
                        referenceType={referenceType}
                        customFieldGroupUI={customFieldSelectedToEdit!}
                    />
                </If>
            </Row>
        </>
    )
}
