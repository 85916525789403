import { Tooltip } from 'antd';

import { MarkupModeSvgIcon } from '@components/Icons/MarkupModeSvgIcon';

interface IMarkupModeButtonProps {
  handleOnClick: () => void;
  isActive: boolean;
}

export const MarkupModeButton = (props: IMarkupModeButtonProps) => {
  const { handleOnClick, isActive } = props;

  return (
    <Tooltip placement="bottom" title={'Markup Mode'}>
      <MarkupModeSvgIcon className="cursor-pointer" onClick={handleOnClick} height="22" width="22" isActive={isActive} />
    </Tooltip>
  );
};
