import React, { useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';

import { ColorPicker, ColorPickerProps, Divider, GetProp, theme, Tooltip } from 'antd';
import Moveable from 'react-moveable';

import { IShape } from '../../models/shape.model';
import { ShapeTypeEnum } from '../../models/enumerations/shape-type.enum';
import { RectangleSvgIcon } from '@components/Icons/RectangleSvgIcon';
import { CircleSvgIcon } from '@components/Icons/CircleSvgIcon';
import { ArrowUpRightSvgIcon } from '@components/Icons/ArrowUpRightSvgIcon';
import { CloseSvgIcon } from '@components/Icons/CloseSvgIcon';
import { TextShapeSvgIcon } from '@components/Icons/TextShapeSvgIcon';
import { ColorPickerSvgIcon } from '@components/Icons/ColorPickerSvgIcon';
import { useAppDispatch, useAppSelector } from '@store/store';
import { createEntity, deleteEntity, getEntities as getShapes, patchEntity as patchShape } from '@store/slices/shape';
import { isFulfilled } from '@reduxjs/toolkit';
import { useContainerFileContex } from '@components/Attachment/FilePreview/ContainerFileProvider';
import { MarkupModeSvgIcon } from '@components/Icons/MarkupModeSvgIcon';
import { useTranslation } from 'react-i18next';
import { ChangesSavedMessage, ErrorMessage, SavingChangesMessage } from './MarkupStatusBar';
import { convertToAbsolutePosition, convertToRelativePosition } from './MarkupCalcUtils';

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string | { cleared: any }>;

interface IMarkupEditorProps {
  fileUrl: string;
  width: string;
  height: string;
  handleOnClose: () => void;
  attachmentId?: number;
  pageNumber?: number;
}

interface Position {
  x: number;
  y: number;
}

export const MarkupEditor = (props: IMarkupEditorProps) => {
  const { fileUrl, width, height, handleOnClose, attachmentId, pageNumber } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [targets, setTargets] = useState<IShape[]>([]);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const markupBarFullScreenRef = React.useRef<HTMLDivElement | null>(null);

  // const [size, setSize] = useState({ width: 0, height: 0 });
  const [factor, setFactor] = useState({ x: 0, y: 0 });

  const updateSize = () => {
    if (imageRef.current) {
      const { offsetWidth, offsetHeight, naturalWidth, naturalHeight } = imageRef.current;

      console.log('*** offsetWidth:' + Math.round(offsetWidth));
      console.log('*** offsetHeight:' + Math.round(offsetHeight));

      console.log('*** naturalWidth:' + Math.round(naturalWidth));
      console.log('*** naturalHeight:' + Math.round(naturalHeight));

      // setSize({ width: offsetWidth, height: offsetHeight });
      const factorX = Math.round(naturalWidth) / offsetWidth;
      const factorY = Math.round(naturalHeight) / offsetHeight;

      console.log('*** factorX:' + factorX);
      console.log('*** factorY:' + factorY);

      setFactor({ x: factorX, y: factorY });
    }
  };

  useEffect(() => {
    // Measure size initially
    updateSize();

    // Add resize event listener
    window.addEventListener('resize', updateSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [imageRef]);

  const { scale } = useContainerFileContex();
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [markupBarHeight, setMarkupBarHeight] = useState(0);

  const [dragStart, setDragStart] = useState<Position | null>(null);

  const [selectedShape, setSelectedShape] = useState<IShape | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);

  const [panelActive, setPanelActive] = useState<keyof typeof ShapeTypeEnum | undefined>();

  const [color, setColor] = useState<Color>('#FF7A45');
  const selectedColor = useMemo<string>(() => (typeof color === 'string' ? color : color!.toHexString()), [color]);

  const {
    token: { colorPrimaryBg },
  } = theme.useToken();

  const { entities: shapeList, updating, errorMessage } = useAppSelector(state => state.Shape);

  useEffect(() => {
    setMarkupBarHeight(markupBarFullScreenRef?.current?.clientHeight || 0);

    dispatch(getShapes({ attachmentId: attachmentId ? attachmentId : 0, pageNumber: pageNumber ? pageNumber : 0, queryParams: {} }));
    // if (containerRef.current) {
    //   const { offsetWidth, offsetHeight } = containerRef.current;
    //   setSize({ width: offsetWidth, height: offsetHeight });
    // }
  }, [attachmentId, pageNumber, dispatch]);

  useEffect(() => {
    const mutableShapeList = shapeList.map(shape => ({ ...convertToRelativePosition(shape, factor.x, factor.y) }));
    setTargets(mutableShapeList);
  }, [shapeList, factor]);

  const addShape = (type: ShapeTypeEnum, text?: string) => {
    setPanelActive(type);
    let shape: IShape = {
      id: '',
      left: 200,
      top: 160,
      width: 100,
      height: 100,
      type: type,
      text: text,
      color: selectedColor,
      rotate: 0,
      ref: undefined,
    };

    const newShape = convertToAbsolutePosition(shape, factor.x, factor.y);

    dispatch(
      createEntity({ entity: newShape, attachmentId: attachmentId ? attachmentId : 0, pageNumber: pageNumber ? pageNumber : 0 })
    ).then(data => {
      if (isFulfilled(data)) {
        newShape.id = data.payload.id;
        setTargets([...targets, newShape]);
      }
    });
  };

  const handleTextChange = e => {
    const updatedTargets = targets.map(shape => {
      if (shape.id === selectedShape?.id) {
        const updatedShape = { ...shape, text: e.target.value };
        dispatch(patchShape(convertToAbsolutePosition(updatedShape, factor.x, factor.y)));
        return updatedShape;
      } else {
        return shape;
      }
    });
    setTargets(updatedTargets);
  };

  const handleTextClick = shape => {
    setSelectedShape(shape);
    if (shape.type === ShapeTypeEnum.TEXT) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const onDelete = () => {
    if (selectedShape) {
      const updatedTargets = targets.filter(target => target.id !== selectedShape.id);
      setTargets(updatedTargets);
      setSelectedShape(undefined);
      if (selectedShape.id) {
        dispatch(deleteEntity(selectedShape.id));
      }
    }
  };

  const handleOnDrag = (shape, index, target, left, top) => {
    const updatedTargets = targets.slice();
    updatedTargets[index] = { ...shape, left, top };
    setTargets(updatedTargets);
    target.style.left = `${left}px`;
    target.style.top = `${top}px`;

    dispatch(patchShape(convertToAbsolutePosition(updatedTargets[index], factor.x, factor.y)));
  };

  const handleOnResize = (shape, index, target, width, height, drag) => {
    const left = drag.beforeTranslate[0];
    const top = drag.beforeTranslate[1];

    const updatedTargets = targets.slice();
    updatedTargets[index] = { ...shape, width, height, left, top };
    setTargets(updatedTargets);
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;

    target.style.left = `${left}px`;
    target.style.top = `${top}px`;

    dispatch(patchShape(convertToAbsolutePosition(updatedTargets[index], factor.x, factor.y)));

    // print width and height in console
    console.log(`width: ${width}`);
    console.log(`height: ${height}`);
  };

  const handleOnRotate = (shape, index, target, beforeRotation) => {
    const updatedTargets = targets.slice();
    updatedTargets[index] = { ...shape, rotate: beforeRotation };
    setTargets(updatedTargets);
    target.style.transform = `rotate(${beforeRotation}deg)`;

    dispatch(patchShape(convertToAbsolutePosition(updatedTargets[index], factor.x, factor.y)));
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
    setDragging(true);
    const { clientX, clientY } = event;
    setDragStart({ x: clientX, y: clientY });
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!dragging || !dragStart) return;

    const { clientX, clientY } = event;
    const deltaX = clientX - dragStart.x;
    const deltaY = clientY - dragStart.y;

    if (scale && scale > 1) {
      if (containerRef.current?.clientWidth && scale) {
        const leftX = scale * containerRef.current?.clientWidth - containerRef.current?.clientWidth;

        setPosition(prevPosition => {
          let xPosition = prevPosition.x + deltaX;
          if (leftX === 0) {
            xPosition = 0;
          } else {
            xPosition = prevPosition.x + deltaX;
          }
          return {
            x: xPosition,
            y: prevPosition.y + deltaY,
          };
        });

        setDragStart({ x: clientX, y: clientY });
      }
    } else {
      setPosition(() => ({ x: 0, y: 0 }));
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    setDragging(false);
    setDragStart(null);
  };

  return (
    // <div style={{overflow: 'scroll'}}>
    <div>
      <div
        ref={markupBarFullScreenRef}
        className="flex flex-row justify-between"
        style={{ backgroundColor: '#FFFFFF', zIndex: 2, position: 'relative' }}
      >
        <div className="flex flex-row items-center justify-start mt-5">
          <div className="ml-16 mr-16">
            <MarkupModeSvgIcon height="22" width="22" isActive={true} />
          </div>
          <div className="ml-5 mr-16">{t('markup.title')}</div>
          <div className="mt-5 mr-8">
            <CloseSvgIcon onClick={handleOnClose} />
          </div>
          <Divider type="vertical" style={{ height: '32px' }} />
        </div>
        <div className="flex flex-row items-center justify-center mt-6">
          <Divider type="vertical" style={{ height: '32px' }} />
          <div className="ml-5 mr-16">
            <CircleSvgIcon onClick={() => addShape(ShapeTypeEnum.CIRCLE)} isActive={panelActive === ShapeTypeEnum.CIRCLE} />
          </div>
          <div className="mr-16">
            <ArrowUpRightSvgIcon onClick={() => addShape(ShapeTypeEnum.ARROW)} isActive={panelActive === ShapeTypeEnum.ARROW} />
          </div>
          <div className="mr-16">
            <RectangleSvgIcon onClick={() => addShape(ShapeTypeEnum.RECTANGLE)} isActive={panelActive === ShapeTypeEnum.RECTANGLE} />
          </div>
          <div className="mr-8">
            <TextShapeSvgIcon onClick={() => addShape(ShapeTypeEnum.TEXT, 'Sample Text')} isActive={panelActive === ShapeTypeEnum.TEXT} />
          </div>
          <Divider type="vertical" style={{ height: '32px' }} />
          <div className="ml-5 mr-24">
            <ColorPicker value={color} onChange={setColor}>
              <ColorPickerSvgIcon selectedColor={selectedColor} />
            </ColorPicker>
          </div>
          <div className="ml-8 mr-8">
            <Tooltip placement="bottom" title={t('markup.deleteShape')}>
              <CloseSvgIcon onClick={() => onDelete()} />
            </Tooltip>
          </div>
          <Divider type="vertical" style={{ height: '32px' }} />
        </div>

        <div className="flex flex-row items-center justify-end mr-16">
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          {updating && <SavingChangesMessage />}
          {!updating && !errorMessage && <ChangesSavedMessage />}
        </div>
      </div>

      <div
        className="zoomable-image-container select-none justify-content-center items-center"
        ref={containerRef}
        style={{
          width: `${width}`,
          height: `calc(${height} - ${markupBarHeight}px)`,
          maxWidth: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
          touchAction: 'none',

          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          zIndex: 1,
          position: 'relative',
          marginLeft: '100px',
          paddingLeft: '-50px',

          // width: '100%',
          // minWidth: '100%',
          // minHeight: '100%',
          // objectFit: 'contain',
        }}
      >
        <img
          // className="zoomable-image"
          src={fileUrl}
          alt="Selected File"
          ref={imageRef}
          loading="lazy"
          style={{
            // transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            // touchAction: 'none',
            // width: '100%',
            // minWidth: '100%',
            // minHeight: '100%',
            objectFit: 'contain',
            objectPosition: 'top left',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
        <div
          className="flex flex-row items-center"
          style={{
            touchAction: 'none',
            width: '100%',
            // width: '826px',
            // height: '620px',
            minWidth: '100%',
            minHeight: '100%',
            objectFit: 'contain',
            // objectPosition: 'top left',
            // maxWidth: '100%',
            // maxHeight: '100%',

            // marginLeft: '100px',
          }}
        >
          {targets.map((shape, index) => (
            <div
              key={shape.id}
              style={{
                position: 'absolute',
                left: shape.left,
                top: shape.top,
                width: shape.width,
                height: shape.height,
                backgroundColor:
                  shape.type === ShapeTypeEnum.TEXT || shape.type === ShapeTypeEnum.ARROW ? 'transparent' : 'rgba(255, 255, 255, 0.5)',
                border: shape.type === ShapeTypeEnum.TEXT || shape.type === ShapeTypeEnum.ARROW ? 'none' : `2px solid ${shape.color}`,
                borderRadius: shape.type === ShapeTypeEnum.CIRCLE ? '50%' : '0%',
                transform: `rotate(${shape.rotate}deg)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                wordBreak: 'break-word',
                textAlign: 'center',
                color: shape.color,
              }}
              ref={el => (targets[index].ref = el)}
              onClick={() => handleTextClick(shape)}
            >
              {shape.type === ShapeTypeEnum.TEXT && isEditing && selectedShape && selectedShape.id === shape.id ? (
                <input
                  type="text"
                  value={shape.text}
                  onChange={handleTextChange}
                  onBlur={handleBlur}
                  autoFocus
                  style={{
                    width: '150px',
                    height: '30px',
                    textAlign: 'center',
                    border: '1px',
                    outline: 'none',
                    background: 'transparent',
                    backgroundColor: colorPrimaryBg,
                  }}
                />
              ) : shape.type === ShapeTypeEnum.ARROW ? (
                <svg
                  viewBox="0 0 100 100"
                  width={shape.width}
                  height={shape.height}
                  style={{ fill: shape.color, transform: `rotate(${shape.rotate}deg)`, backgroundColor: 'transparent', border: 'none' }}
                >
                  <line x1="10" y1="50" x2="90" y2="50" stroke={shape.color} strokeWidth="2" />
                  <polygon points="95,50 75,45 75,55" fill={shape.color} />
                </svg>
              ) : (
                shape.text
              )}
            </div>
          ))}
          {targets.map((shape, index) => (
            <Moveable
              flushSync={flushSync}
              key={shape.id}
              target={shape.ref}
              draggable={selectedShape?.id === shape.id}
              throttleDrag={1}
              edgeDraggable={false}
              startDragRotate={0}
              throttleDragRotate={0}
              resizable={selectedShape?.id === shape.id}
              keepRatio={false}
              throttleResize={1}
              renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
              rotatable={selectedShape?.id === shape.id}
              throttleRotate={0}
              rotationPosition={'top'}
              onDrag={({ target, left, top }) => {
                handleOnDrag(shape, index, target, left, top);
              }}
              onResize={({ target, width, height, drag }) => {
                handleOnResize(shape, index, target, width, height, drag);
              }}
              onRotate={({ target, beforeRotation }) => {
                handleOnRotate(shape, index, target, beforeRotation);
              }}
              origin={false}
              hideDefaultLines={selectedShape?.id !== shape.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};