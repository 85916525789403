// WhiteList File Images Upload Extentions - (Valid Extentions)
export const VALID_IMAGES_EXTENSIONS = [
    // Images
    '.tif', 
    '.tiff',
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.ico',
    '.dib',
    '.jpc',
    '.jpeg-ls',
    '.jpeg2000',
    '.webp',
    // '.heic',
    // '.heif'
]