import { IShape } from '@models/shape.model';

export const convertToAbsolutePosition = (shape: IShape, factorX: number, factorY: number) => {
  return {
    ...shape,
    left: shape.left ? shape.left / factorX : 0,
    top: shape.top ? shape?.top / factorY : 0,
    width: shape.width ? shape.width / factorY : 0,
    height: shape.height ? shape.height / factorY : 0,
  };
};

export const convertToRelativePosition = (shape: IShape, factorX: number, factorY: number) => {
  return {
    ...shape,
    left: shape.left ? shape.left * factorX : 0,
    top: shape.top ? shape?.top * factorY : 0,
    width: shape.width ? shape.width * factorY : 0,
    height: shape.height ? shape.height * factorY : 0,
  };
};
