import { CheckOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import { useTranslation } from 'react-i18next';

export const ChangesSavedMessage = () => {
  const {
    token: { colorSuccessText },
  } = theme.useToken();
  const { t } = useTranslation();
  return (
    <div className="flex" style={{ gap: '0.5rem' }}>
      <span style={{ color: colorSuccessText, fontSize: '14px' }}>{t('markup.messages.changesSaved')}</span>
      <CheckOutlined style={{ fontSize: '14px', color: colorSuccessText }} />
    </div>
  );
};

export const SavingChangesMessage = () => {
  const {
    token: { colorTextTertiary },
  } = theme.useToken();
  const { t } = useTranslation();
  return (
    <div className="flex" style={{ gap: '0.5rem' }}>
      <span style={{ color: colorTextTertiary, fontSize: '14px' }}>{t('markup.messages.saving')}</span>
      <LoadingOutlined style={{ fontSize: '14px', color: colorTextTertiary }} />
    </div>
  );
};

interface IErrorMessageProps {
  errorMessage: string;
}

export const ErrorMessage = (props: IErrorMessageProps) => {
  const { errorMessage } = props;

  const {
    token: { colorTextTertiary, colorWarningBg, colorWarningBorder },
  } = theme.useToken();
  const { t } = useTranslation();

  return (
    <div className="flex px-8 py-4" style={{ gap: '0.5rem', backgroundColor: colorWarningBg }}>
      <WarningOutlined style={{ fontSize: '14px', color: colorWarningBorder }} />
      <span style={{ color: colorTextTertiary, fontSize: '14px' }}>
        {t('markup.messages.errorSaving')}: {errorMessage}
      </span>
    </div>
  );
};
