/* eslint-disable react-hooks/exhaustive-deps */
import { generate } from '@ant-design/colors';
import { Menu, theme } from 'antd'
import { useEffect, useLayoutEffect, useState } from 'react'
import { AppstoreOutlined, LineChartOutlined, ProfileOutlined, SettingOutlined, ShopOutlined, SnippetsOutlined, ToolOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useThemeProvider, SITE_RISE_OFF_WHITE, WHITE } from '@providers/ThemeProvider';
import { ADMIN_ROLE_KEY, APPS_IDS, DSR_LINK } from '@constants/core';
import { useAuth } from '@providers/AuthProvider';
import { isArrayWithValues } from '@shared/util/array-util';
import { useFeatureFlagsProvider } from '@providers/FeatureFlagsProvider';
import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';
import { useTranslation } from 'react-i18next';
import { configuration } from '@environments/env';
import "./Menu.less";

export interface MenuInfo {
  key: string;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export const LocationLabel = () => {
  const { t } = useTranslation();
  return <span data-testid="locationLabelTestId">{t('generic.locations')}</span>;
};

export const BiReportsLabel = () => {
  const { t } = useTranslation();
  return <span data-testid="biReporstTestId">{t('generic.biReports')}</span>;
};

export const CompanyLabel = () => {
  const { t } = useTranslation();
  return <span data-testid="companiesLabelTestId">{t('generic.companies')}</span>;
};

export interface IMainMenu {
  menuItemSelectedPlacement: 'left' | 'right';
}

export const MainMenu = (props: IMainMenu) => {
  const { themeConfig } = useThemeProvider();
  const { isFlagEnabled } = useFeatureFlagsProvider();
  const navigate = useNavigate();
  let location = useLocation();
  const getKeyOfItemActive = (): string => location?.pathname.split('/')[1];
  const [activeMenuItem, setActiveMenuItem] = useState([getKeyOfItemActive()]);

  const { user: userLogged } = useAuth();

  const goTo = (url: string, info: MenuInfo) => {
    const { pathname, search } = location;
    if (pathname === url) {
      info.domEvent?.metaKey ? window.open(`${url}${search}`) : navigate(`${url}${search}`, { state: { refresh: true } });
    } else {
      info.domEvent?.metaKey ? window.open(url) : navigate(url);
    }
    info?.key && setActiveMenuItem([info?.key]);
  };

  const goToDSR = () => {
    window.open(DSR_LINK);
  };

  useEffect(() => {
    const keyProp: string = getKeyOfItemActive();
    keyProp && setActiveMenuItem([keyProp]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const menuListProps: Partial<ItemType>[] = [
    //Dashboard Should be always on top regardless of order provided in any design / figma
    {
      key: 'dashboard',
      icon: <AppstoreOutlined />,
      label: 'Dashboard',
      onClick: info => goTo('/dashboard', info),
    },

    {
      key: 'location',
      icon: <ShopOutlined />,
      label: <LocationLabel />,
      onClick: info => goTo('/location', info),
    },

    ...(configuration && configuration.APP_ID && configuration.APP_ID === APPS_IDS.LEVEL5
      ? [
          {
            key: 'dsr',
            icon: <ProfileOutlined />,
            label: 'DSR',
            onClick: () => goToDSR(),
          },
        ]
      : []),

    ...(isFlagEnabled(FeatureFlagOptionEnum.COMPANIES)
      ? [
          {
            key: 'companies',
            icon: <UsergroupAddOutlined />,
            label: <CompanyLabel />,
            onClick: (info: MenuInfo) => goTo('/companies', info),
          },
        ]
      : []),

    ...(isFlagEnabled(FeatureFlagOptionEnum.BI_REPORTS)
      ? [
          {
            key: 'bi',
            icon: <LineChartOutlined />,
            label: <BiReportsLabel />,
            onClick: (info: MenuInfo) => goTo('/bi', info),
          },
        ]
      : []),

    ...(isFlagEnabled(FeatureFlagOptionEnum.TABLEAU)
      ? [
          {
            key: 'executive-report',
            icon: <SnippetsOutlined />,
            label: 'Executive Report',
            onClick: (info: MenuInfo) => goTo('/executive-report', info),
          },
        ]
      : []),

    // Temporary Hide Feature
    ...(false && isArrayWithValues(userLogged?.authorities) && userLogged?.authorities?.includes(ADMIN_ROLE_KEY)
      ? [
          {
            key: 'admin',
            icon: <ToolOutlined />,
            label: 'Admin',
            onClick: (info: any) => goTo('/admin/access-and-permissions/users', info),
          },
        ]
      : []),

    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
      onClick: info => goTo('/settings', info),
    },
    //Settings Should be always on bottom most regardless of order provided in any design / figma
  ];

  const itemsOfMenu: ItemType[] = menuListProps as ItemType[];

  useLayoutEffect(() => {
    if (themeConfig && themeConfig.primaryColor) {
      const palette = generate(themeConfig.primaryColor, {
        theme: 'dark',
        backgroundColor: WHITE,
      });
      document.documentElement.style.setProperty(`--menu-item-bar-left-color`, palette[9]);
    }
  }, []);

  const {
    token: { colorFillQuaternary },
  } = theme.useToken();
  const dynamicBackground = `linear-gradient(0deg, ${colorFillQuaternary} 0%, ${colorFillQuaternary}  100%), ${themeConfig.secondaryColor} `;

  return (
    <Menu
      key={`${activeMenuItem}`}
      rootClassName={props.menuItemSelectedPlacement === 'left' ? 'menu-item-selected-left' : 'menu-item-selected-right'}
      style={{
        background: dynamicBackground,
        color: SITE_RISE_OFF_WHITE,
      }}
      theme="dark"
      mode="inline"
      defaultSelectedKeys={activeMenuItem}
      items={itemsOfMenu}
    />
  );
};
