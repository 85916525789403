import { blue } from "@ant-design/colors";
import { CheckCircleTwoTone, FileImageOutlined } from "@ant-design/icons";
import { If } from "@components/Utils/Structural";
import { AttachmentReferenceEnum } from "@models/enumerations/attachment-reference-enum.model";
import { IPunchListItem } from "@models/punch-list.model";
import { useNotificationsProvider } from "@providers/NotificationsProvider";
import { useAppDispatch } from "@store/store";
import { isNumber } from "lodash";
import { getByReferenceTypeAndReferenceId } from "@store/slices/attachment";
import { DEFAULT_TIMING_LOADING } from "@constants/core";
import UploadModal from "@components/Attachment/UploadModal";

interface IUploadFilePunchListItemModalProps {
  punchListItem: IPunchListItem,
  openModal: boolean,
  toogleModal: () => void
}

export const ACCEPT_IMAGES_TO_UPLOAD =  "image/png, image/jpg, image/jpeg, image/webp"

export const UploadFilePunchListItemModal = (props: IUploadFilePunchListItemModalProps) => {

    const dispatch = useAppDispatch();

    const { punchListItem, openModal, toogleModal } = props;

    const { openNotification } = useNotificationsProvider();

    const titleModal = "Drag and Drop an Image";

    const getAllAttachments = () => {}

    const handleCloseuploadModals = () => {
        toogleModal();
    }

    const onSuccessUploadedFile = () => {
        openNotification({
            type: "info",
            config: {
                icon: <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor={[blue[5],blue[0]]} />,
                message: `Image in Item #${punchListItem?.punchlistNumber} was Uploaded`,
                description: `You will see the preview once the processing is complete`
            }
        });
        
        const punchListItemId = Number(punchListItem?.id);
        const isPunchListItemIdNumber = isNumber(Number(punchListItemId));

        if (isPunchListItemIdNumber) {
            setTimeout(() => {
                dispatch(getByReferenceTypeAndReferenceId({ 
                    referenceType: AttachmentReferenceEnum.PUNCH_LIST_ITEM,
                    referenceId: punchListItemId
                }))
            }, DEFAULT_TIMING_LOADING);
        }
    }
    
    const uploadPropsModal = {
        accept: ACCEPT_IMAGES_TO_UPLOAD
    }

    return (
        <If condition={Boolean(openModal && punchListItem?.id)}>
            <UploadModal 
                entityType={AttachmentReferenceEnum.PUNCH_LIST_ITEM} 
                referenceId={+punchListItem?.id!} 
                attachmentId={null} 
                getAllAttachments={getAllAttachments} 
                handleCloseuploadModals={handleCloseuploadModals} 
                onUploaded={onSuccessUploadedFile}
                titleModal={titleModal}
                draggerContent={<DraggerContent />}
                uploadPropsModal={uploadPropsModal}
            />
        </If>
    )
}

export const DraggerContent = () => {
    return (
      <>
        <p className="ant-upload-drag-icon">
          <FileImageOutlined />
        </p>
        <p className="ant-upload-text">Click or drag an image to this area to upload</p>
        <p className="ant-upload-hint">
            Supported formats: JPG, PNG, WEBP, JPEG...
        </p>
      </>
    )
}