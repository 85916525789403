import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';
import { useFeatureFlagsProvider } from '@providers/FeatureFlagsProvider';
import { useLayoutContex } from '@providers/LayoutProvider';
import { MarkupEditorStandalone } from './MarkupEditorStandalone';

export const Markup = () => {
  const { isFlagEnabled } = useFeatureFlagsProvider();

  const { setHeaderLabelEntityState } = useLayoutContex();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    setHeaderLabelEntityState && setHeaderLabelEntityState(`${t('markup.title')}`);
    return () => setHeaderLabelEntityState && setHeaderLabelEntityState('');
  }, [setHeaderLabelEntityState, t]);

  const fileUrl = 'https://demo-staging.siterise.app/media/floor-plan-sample.png';

  return (
    <>
      {isFlagEnabled(FeatureFlagOptionEnum.MARKUP) === true && (
        <>
          <MarkupEditorStandalone fileUrl={fileUrl} attachmentId={44151} pageNumber={1} />
        </>
      )}
    </>
  );
};
