/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect } from 'react';
import { Layout, Skeleton, Tabs, TabsProps, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { MainHeader } from '@components/Layout/HeaderLayout/MainHeader';
import { useLayoutContex } from '@providers/LayoutProvider';
import { RevealContainer } from '@components/BI/RevealContainer';
import { useRedirectIfFeatureIsNotEnabled } from '@HOOKs/UseRedirectIfFeatureIsNotEnabled';
import { FeatureFlagOptionEnum } from '@models/enumerations/feature-flag-option.enum';
import { If } from '@components/Utils/Structural';
import { RevealContextProvider, useRevealContextProvider } from '@components/BI/RevealProvider';
import { IBIDashboard } from '@models/bi-dashboard.model';
import { AppDispatch, useAppSelector } from '@store/store';
import { useDispatch } from 'react-redux';
import { getEntities } from '@store/slices/bi-dashboard';

export const RevealBIPage = () => {
  return (
    <>
      <RevealContextProvider>
        <RevealLoaderContainer />
      </RevealContextProvider>
    </>
  );
};

export const RevealLoaderContainer = () => {
  const { isPermisionsLoaded } = useRevealContextProvider();

  return (
    <>
      <If condition={Boolean(isPermisionsLoaded)}>
        <RevealBI />
      </If>
      <If condition={!Boolean(isPermisionsLoaded)}>
        <Layout>
          <MainHeader />
          <Content className="overflow-auto">
            <Skeleton loading={true} paragraph={{ rows: 24 }} active={true} className="pl-20 pr-20 pt-20 pb-20"></Skeleton>
          </Content>
        </Layout>
      </If>
    </>
  );
};

const getTabItems: (dashboards: IBIDashboard[]) => TabsProps['items'] = (dashboards: IBIDashboard[]) =>
  dashboards.map(dashboard => ({
    key: String(dashboard.id),
    label: dashboard.label,
    children: <RevealContainer dashboardName={dashboard.fileName} />,
    className: 'h-full',
  }));

export const RevealBI = () => {
  useRedirectIfFeatureIsNotEnabled({ feature: FeatureFlagOptionEnum.BI_REPORTS });

  const dispatch = useDispatch<AppDispatch>();

  const { data: dashboards } = useAppSelector(state => state.BIDashboard);

  const { setHeaderLabelEntityState } = useLayoutContex();

  const {
    token: { colorBgLayout, colorBgContainer, marginLG },
  } = theme.useToken();

  useEffect(() => {
    dispatch(getEntities());
  }, []);

  useLayoutEffect(() => {
    setHeaderLabelEntityState && setHeaderLabelEntityState('BI Dashboards');
  }, []);

  return (
    <Layout>
      <MainHeader />
      <Content className="w-full h-full">
        {dashboards.length > 0 && (
          <Tabs
            items={getTabItems(dashboards)}
            className="custom-tabs imb-0 overflow-hidden"
            style={{ backgroundColor: colorBgLayout }}
            tabBarStyle={{
              backgroundColor: colorBgContainer,
              paddingLeft: `${marginLG}px`,
              marginBottom: 0,
            }}
            destroyInactiveTabPane
          />
        )}
      </Content>
    </Layout>
  );
};


